@tailwind base;
@layer base {
    img {
      @apply inline-block;
    }

    html {
      font-family: 'Sora', sans-serif;
    }    
  }
@tailwind components;
@tailwind utilities;

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}